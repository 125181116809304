body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.print-only {
  display: none;
}

@media print
{    
    .no-print, .no-print * {
        display: none !important;
    }

    .print-only {
      display: block !important;
    }

    .scheduleWrap, .scheduleWrap * {
      font-size: 10px !important;
    }

    .scheduleWrap .printNoMarg, .scheduleWrap .printNoMarg * {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .scheduleWrap .printNoMarg, .scheduleWrap .printNoMarg td *, .scheduleWrap .printNoMarg tr * {
      padding: 2px !important;
      line-height: 1 !important;
    }

    .scheduleWrap .printNoMarg .margHeader, .scheduleWrap .printNoMarg .margHeader * {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }

    #root {
      height: auto !important;
    }

    html, body {
      height: auto !important;
      width: auto !important;
    }

    @page {
      size: landscape;
    }
}
